import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Table, Popover, notification, message, Input, Form } from 'antd'
import { RightOutlined, MenuOutlined } from '@ant-design/icons'

import Main from '../components/Main'
import {
  CLIENT_UPDATE,
  CLIENTS_DISCOVER,
  CLIENTS_GET,
  requestToServer,
  getLocalData
} from '../lib/helper'

import { mainColor } from '../config'


const SearchBox = ({value, onChange}) => {
  return(
    <Input
      onChange={ onChange }
       allowClear value={value}
    />
  )
}


export default function Home() {

  const [clients, setClients] = useState([])
  const [clientsDisplayed, setClientsDisplayed] = useState([])
  const [search, setSearch] = useState('')

  const history = useHistory()

  const getClients = () => {
    requestToServer({}, CLIENTS_GET).then(r => {
      setClients(r.clients)
      if ( search ) {
        filterClients({search, clients:r.clients})
      } else {
        setClientsDisplayed(r.clients)
      }
    })
  }

  const filterClients = ({search, clients}) => {
    setClientsDisplayed( clients.filter(client => {
      let aSearch = search.toLowerCase()
      let selected = false
      if ( client.platformName.toLowerCase().indexOf( aSearch ) !== -1 ) { selected = true } // the same for id-subscription
      if ( client.subscriptionId ) {
        if ( client.subscriptionId.toLowerCase().indexOf( aSearch ) !== -1 ) { selected = true } // the same for id-subscription
      }
      return selected
    }) )
  }

  useEffect(() => {

    let localData = getLocalData()
    if ( localData && localData.token ) {
      getClients()
    } else {
      history.push('/login')
    }
  }, [])

  let extraButtons = [
    {
      name: 'Logs',
      action: () => history.push('/logs')
    },
    {
      name: 'Descubrir',
      action: () => { 
        requestToServer({}, CLIENTS_DISCOVER).then(r => {
          getClients()
          notification.info({message:'actualizado'})
        })
      }
    }
  ]

  let columns = [
    {
      title : '',
      key: 'actions',
      render: (text, record, index) => {
        return(
          <Popover
            content={<OptionsAction record={record} />}
            title={record.platformName}
            trigger="click"
          >
            <MenuOutlined style={{color:mainColor}}
            />
          </Popover>
        )
      }
    },
    {
      title : 'Nombre',
      key: 'platformName',
      dataIndex: 'platformName'
    },
    {
      title : 'Multi',
      key: 'isMultiStore',
      render: (text, record, index) => {
        if (record.isMultiStore == null ) {
          return('')
        } else if (record.isMultiStore) {
          return('multi')
        } else {
          return('mb')
        }
      }
    },

    {
      title : 'Up',
      key: 'online',
      render: (text, record, index) => {
        let styles = {color:'white', paddingLeft:'4px', borderRadius:'3px'}
        if ( record.online ) {
          styles.backgroundColor = mainColor
        } else {
          styles.backgroundColor = 'red'
        }

        return (
          <div style={styles}>
            {record.backendVersion}
          </div>
        )
      }
    },
    {
      title : 'Clientes',
      key: 'customers',
      dataIndex: 'customers'
    },
    {
      title : 'Canjeados',
      key: 'redeemeds',
      dataIndex: 'redeemeds'
    },
    {
      title : 'Canjes',
      key: 'redeemedsLimit',
      dataIndex: 'redeemedsLimit'
    },
    
  ]

  const OptionsAction = ({record}) => {

    let { platformName } = record

    return(
      <div>
        <Button
          onClick={()=>{
            requestToServer({platformName}, CLIENT_UPDATE).then(r => {
              getClients()
              message.info('actualizado: ' + platformName)
            })
          }}
        >actualizar</Button>
        <Button
          onClick={ ()=>history.push('/client/' + platformName ) }
        >ver</Button>
      </div>
    )
  }


  return (
    <Main
      pageHeader={{ title:'' , backIcon: false, extraButtons }}
      subTitle={<SearchBox value={search} onChange={ e => {
          setSearch(e.target.value)
          filterClients({search:e.target.value, clients})
        }}/>}
    >

      <Table columns={columns} dataSource={clientsDisplayed} rowKey='id' size='small' pagination={{pageSize: 50}}/>
    </Main>
  )
}