import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Input, Button, message, Divider, Popconfirm, notification, Select, Row, Col, Space, Modal, InputNumber, Switch } from 'antd'

import Main from '../components/Main'
import {
  CLIENT_UPDATE,
  UPDATE_SETTINGS,
  CLIENT_INFO,
  CLIENT_SAVE,
  CLIENT_STOP,
  CLIENT_START,
  CLIENT_DELETE,
  requestToServer
} from '../lib/helper'

import {paymetMethod} from '../config'

/*
const RedeemFree = ({ clientInfo, platformName, updateAllInfo }) => { // not used these time

  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(0)

  return (
    <div>
      <div>
        redeemFree : { clientInfo && clientInfo.store && clientInfo.store.redeemFree} <Button size='small' onClick={()=>setShowModal(true)}>set</Button>
      </div>
      <Modal
        title='Establecer el valor de redeemFree (canjes gratis)'
        visible={showModal}
        onCancel={ () => setShowModal(false) }
        onOk = { () => {
          requestToServer({platformName, key: 'redeemFree', value} , UPDATE_SETTINGS).then( r => {
            requestToServer({platformName}, CLIENT_UPDATE).then(r => {
              updateAllInfo()
              setShowModal(false)
              setValue(0)
            })
          } )
        }}
      >
        <InputNumber value={value} onChange={setValue} />
      </Modal>
    </div>
  )
}
*/

const RedeemBought = ({ clientInfo, platformName, updateAllInfo, isMultiStore }) => {

  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(0)
  const [sendEmail, setSendEmail] = useState(true)

  let currentRedeems = 0
  if ( isMultiStore ) {
    currentRedeems = clientInfo.client.redeemedsLimit
  } else {
    currentRedeems = clientInfo.store.redeemBought
  }

  return (
    <div>
      <div>
        redeemBought : { currentRedeems} <Button size='small' onClick={()=>setShowModal(true)}>add</Button>
      </div>
      <Modal
        title='Añadir canjes a redeemBought'
        visible={showModal}
        onCancel={ () => setShowModal(false) }
        onOk = { () => {
          let key = 'redeemBought'
          if ( isMultiStore ) {
            key = 'addRedeemBoughtMulti'
          }
          requestToServer({platformName, key, value, sendEmail} , UPDATE_SETTINGS).then( r => {
            requestToServer({platformName}, CLIENT_UPDATE).then(r => {
              updateAllInfo()
              setShowModal(false)
              setValue(0)
            })
          } )
        }}
      >
        <div>
          +<InputNumber value={value} onChange={setValue} />
        </div>
        <div style={{paddigTop:'10px'}}>
          <Switch onChange={setSendEmail} checked={sendEmail}/> Enviar email
        </div>

      </Modal>
    </div>
  )
}

/*
const RedeemBoughtMulti = ({ clientInfo, platformName, updateAllInfo }) => {

  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(0)

  return (
    <div>
      <div>
        RedeemBought : {clientInfo && clientInfo.client && clientInfo.client.redeemedsLimit} <Button size='small' onClick={()=>setShowModal(true)}>set</Button>
      </div>
      <Modal
        title='Añadir canjes a redeemBought para MultiStore'
        visible={showModal}
        onCancel={ () => setShowModal(false) }
        onOk = { () => {
          requestToServer({platformName, key: 'addRedeemBoughtMulti', value} , UPDATE_SETTINGS).then( r => {
            requestToServer({platformName}, CLIENT_UPDATE).then(r => {
              updateAllInfo()
              setShowModal(false)
              setValue(0)
            })
            
          } )
        }}
      >
        +<InputNumber value={value} onChange={setValue} />
      </Modal>
    </div>
  )
}
*/

export default function Client() {

  const [clientInfo, setClientInfo] = useState(null)
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [paymentType, setPaymentType] = useState(null)
  const [notes, setNotes] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientEmail, setClientEmail] = useState('')

//  const [all, setAll] = useState(null)

  const { platformName } = useParams()

  useEffect(() => {
    updateAllInfo()    
  }, [])


  const updateAllInfo = () => {
    requestToServer({platformName}, CLIENT_INFO).then(r => { // should call from db
      setClientInfo(r)
      setSubscriptionId(r.client.subscriptionId)
      setPaymentType(r.client.paymentType)
      setNotes(r.client.notes)
      setClientName(r.client.clientName)
      setClientEmail(r.client.clientEmail)
    })
  }



  return (

    <Main
      pageHeader={{ title: platformName  , back: true }}
    >

    { clientInfo && <div style={{paddingLeft:'10px', paddingRight:'10px', paddingBottom: '10px'}}>



      Tipo: {clientInfo.configPlatform.isMultiStore ? 'MultiStore' : 'OneStore'} <br />


      { clientInfo.configPlatform.isMultiStore ?
        <div>
{/*          <RedeemBoughtMulti clientInfo={clientInfo} platformName={platformName} updateAllInfo={updateAllInfo}/> */}
          <RedeemBought clientInfo={clientInfo} platformName={platformName} updateAllInfo={updateAllInfo} isMultiStore={true} />
          
        </div> : <div>
          Customers : { clientInfo && clientInfo.customers } <br /> 
          <RedeemBought clientInfo={clientInfo} platformName={platformName} updateAllInfo={updateAllInfo} isMultiStore={false}/>
        </div>}


      <Divider />

      <Space>
        <Popconfirm
          title='Seguro de detener la plataforma?'
          okText='Detener'
          onConfirm={ () => requestToServer({platformName}, CLIENT_STOP).then(r => message.info('Plataforma detenida') ) }
        >
          <Button>Detener</Button>
        </Popconfirm>


        <Popconfirm
          title='Seguro de levantar la plataforma?'
          okText='Levantar'
          onConfirm={ () => requestToServer({platformName}, CLIENT_START).then(r => {
            notification.info({message:r})
          } ) }
        >
          <Button>Levantar</Button>
        </Popconfirm>

        <Popconfirm
          title='Seguro de elimiar la plataforma?'
          okText='Eliminar'
          onConfirm={ () => requestToServer({platformName}, CLIENT_DELETE).then(r => {
            notification.info({message:r})
          } ) }
        >
          <Button>Eliminar</Button>
        </Popconfirm>


      </Space>

      <Divider />


      <Row>
        <Col span={14}>
          <Input addonBefore='Cliente:' value={clientName} onChange={e=>setClientName(e.target.value)} placeholder='nombre'/>
        </Col>
        <Col span={10}>
          <Input value={clientEmail} onChange={e=>setClientEmail(e.target.value)} placeholder='email'/>
        </Col>
      </Row>

       <Input addonBefore='SubscId:' value={subscriptionId}
        onChange={ e => setSubscriptionId(e.target.value) }
        addonAfter={
          <Select
            placeholder="Método Pago"
            value={paymentType}
            onChange={setPaymentType}
          >
            { Object.keys(paymetMethod).map( type => {
              return( <Select.Option value={type} key={type}>{paymetMethod[type]}</Select.Option>)
            })}
          </Select>
        }
       />

       <Input.TextArea autoSize={{minRows:2}} value={notes} placeholder='Notas' onChange={e => setNotes(e.target.value) }/>


       <Button
        onClick={ () => requestToServer({
          platformName, subscriptionId, paymentType, notes, clientEmail, clientName
        }, CLIENT_SAVE).then(r => message.info('Actualizado') ) }
       >Guardar</Button>

    </div>}

    </Main>

  )
}