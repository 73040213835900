// for PROD
export const API_SERVER = 'https://pulpo.api.pretii.lat'

// for DEV
//export const API_SERVER = 'http://localhost:8080'


export const paymetMethod = {
  paypal : 'Paypal',
  crypto : 'Cryto',
  bank:    'Transferencia bancaria'
}

export const mainColor = '#3f82f9'