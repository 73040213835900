import React, { useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import { useHistory } from 'react-router-dom'

import Main from '../components/Main'
import {
  LOGIN,
  requestToServer,
  setLocalData
} from '../lib/helper'


export default function Login() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()


  const doLogin = () => {
    requestToServer({email,password}, LOGIN).then(r => {
      if ( r.token ) {
        setLocalData({token:r.token})
        history.push('/')
      } else {
        notification.error({message:r.m})
      }
    })
  }

  return (
    <Main>

      <h1 style={{textAlign:'center', paddingTop:'30px'}}>Ingreso</h1>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={ ()=>{} }
        onFinishFailed={ () => {} }
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          rules={[{ required: true, message: 'Please input your username!' }]}
          value = {email}
          onChange = { e => setEmail(e.target.value)}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          value = {password}
          onChange = { e => setPassword(e.target.value)}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit"
            onClick = { () => doLogin() }
          >
            Ingresar
          </Button>
        </Form.Item>

      </Form>
    </Main>
  )
}