import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Layout, /*PageHeader,*/ Button, Drawer } from 'antd'
//import 'antd/dist/antd.css'
import { PageHeader } from '@ant-design/pro-layout'

import { ArrowLeftOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'

const { Header } = Layout;

const Main = ({ children, pageHeader, subTitle }) => {

  const menuButton = <Button  onClick={()=> setShowMenu(true)}>Menu</Button>

  const [showMenu, setShowMenu] = useState(false);
  const [extra, setExtra] = useState( []  );

  const history = useHistory()

  useEffect(()=>{
    let newExtra = []
    if ( pageHeader ) {
      if ( pageHeader.extraButtons ) {
        pageHeader.extraButtons.forEach( (item,index)=>{
          newExtra.push(<Button key={'button_' + index} onClick={ ()=> item.action() }>{item.name}</Button>)
        })
      }
//      newExtra.push(<Button key="menu" onClick={()=> setShowMenu(true)}>Menu</Button>)

      setExtra(newExtra)
    }
  },[pageHeader])


  return (
    <Layout>
      <Layout.Content style={{maxWidth:'600px', marginLeft:'auto', marginRight:'auto', width:'100%', backgroundColor:'white', marginBottom:'20px'}} className='main-content'>
        { pageHeader && <PageHeader title={pageHeader.title} extra={extra} subTitle={subTitle} backIcon={ pageHeader.back ? <ArrowLeftOutlined /> : false } onBack={() => history.goBack() } />}
        {children}
      </Layout.Content>

      <Drawer title="Menú" placement="right"  visible={showMenu} onClose={()=>setShowMenu(false)} >
         <Link to="/login"><p>Login</p></Link>
      </Drawer>

    </Layout>
  )
}

export default Main
