import React, { useState, useEffect } from 'react'
import { List } from 'antd'

import Main from '../components/Main'
import {
  LOGS,
  requestToServer
} from '../lib/helper'

export default  () => {

  const [logs, setLogs] = useState([])

  useEffect(() => {
    requestToServer({}, LOGS).then( r => setLogs(r.logs) )
  }, [])

  let columns = [

  ]

  return(
    <Main
      pageHeader={{ title: 'Logs'  , back: true }}
    >
      <List
        dataSource={logs}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.platformName}
              description={item.createdAt}
            />
            {item.text}
          </List.Item>
        )}
        pagination={{
          onChange: (page) => {
          },
          pageSize: 50,
        }}
      />
    </Main>
  )
}