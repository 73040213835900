import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { API_SERVER } from '../config'

export const LOGIN            = API_SERVER + '/login'
export const CLIENT_UPDATE    = API_SERVER + '/client/update'
export const CLIENT_INFO      = API_SERVER + '/client/info'
export const CLIENT_SAVE      = API_SERVER + '/client/save'
export const CLIENTS_GET      = API_SERVER + '/client/get-all'
export const CLIENT_STOP      = API_SERVER + '/client/stop'
export const CLIENT_START     = API_SERVER + '/client/start'
export const CLIENT_DELETE    = API_SERVER + '/client/delete'
export const CLIENTS_DISCOVER = API_SERVER + '/client/discover-all'
export const UPDATE_SETTINGS  = API_SERVER + '/client/update-platform-settings'
export const LOGS             = API_SERVER + '/client/logs/get'

let localData = null

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if ( 401 === error.response.status ) { // Unauthorized
    localData = null
    clearLocalData()
  } else {
    return Promise.reject(error)
  }
})


export function requestToServer( bodyData, path ) {

  let localData = getLocalData()

  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if ( localData && localData.token ) {
    headers.Token = localData.token
  }

  return new Promise((resolve, reject) => {

    axios.post(path, bodyData, {headers} ).then(r => {
      if (r){
        resolve( r.data ? r.data : null )
      } else { console.log('ERROR') }
    }).catch(e => console.log('ERROR', e) )

  })
}

// localdata management
export function setLocalData(data) {
  localData = data
  localStorage.removeItem('localData')
  localStorage.setItem('localData', JSON.stringify(localData) )
}
export function getLocalData() {

  if (localData) {
    return localData
  } else {
    let dataCookie = localStorage.getItem('localData')
    if ( dataCookie ) {
      localData = JSON.parse(dataCookie)
      return localData
    } else {
      return null
    }
  }
}
export function clearLocalData() {
  localData = null
  localStorage.removeItem('localData')
}